<template>
  <component :is="orderData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="orderData === undefined">
      <h4 class="alert-heading">Error fetching order data</h4>
      <div class="alert-body">
        No order found with this order id. Check
        <b-link class="alert-link" :to="{ name: 'orders-list' }">
          Order List
        </b-link>
        for other orders.
      </div>
    </b-alert>

    <b-tabs v-if="orderData" class="tabs-primary" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Order Information</span>
        </template>
        <order-edit-tab-information
          :order-data="orderData"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
          />
          <!-- :user-options="userOptions" -->
      </b-tab>

      <!-- Tab: Account -->
      <!-- <b-tab v-if="orderData.id > 0">
        <template #title>
          <feather-icon icon="OrderIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Permission</span>
        </template>
        <order-edit-tab-account :order-data="orderData" class="mt-2 pt-75" />
      </b-tab> -->
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import orderStoreModule from "../orderStoreModule";
import OrderEditTabInformation from "./OrderEditTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    OrderEditTabInformation,
  },
  setup() {
    const orderData = ref(null);
    const userOptions = ref([]);

    const USER_APP_STORE_MODULE_NAME = "app-order";

    const order = {
      id: 0,
      user_id: null,
      product_id: null,
      discount_id: null,
      reserve_date: null,
      order_qty: 0,
      order_amt: 0,
    };

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, orderStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "orders-create") {
        orderData.value = order;
      } else {
        store
          .dispatch("app-order/fetchOrder", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            orderData.value = response.data.order;
            orderData.value.is_active =
              orderData.value.order_statuses_id == 1 ? true : false;
          })
          .catch((error) => {
            console.log("error when fetching order", error);
            if (error.response.status === 404) {
              orderData.value = undefined;
            }
          });
      }
    };

    // const fetchOrders = (ctx, callback) => {
    //   store
    //     .dispatch("app-order/fetchOrders")
    //     .then((response) => {
    //       order.next_id = response.data.next_id.id;
    //       console.log(order);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Error fetching orders list",
    //           icon: "AlertTriangleIcon",
    //           variant: "danger",
    //         },
    //       });
    //     });
    // };

    const refetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", { user: true })
        .then((res) => (userOptions.value = res.data.users))
        .catch((err) => console.error("fail fetching product options ", err));
    };

    onMounted(() => {
      // fetchOrders();
      refetchOptions();
    }),
      refetchData();

    return {
      orderData,
      // fetchOrders,
      refetchData,
      userOptions,
    };
  },
};
</script>

<style></style>
