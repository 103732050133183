<template>
  <div id="order_form" v-if="orderData">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ orderData.id == 0 ? "Add" : "Edit" }} Order
      </h3>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <!-- Form: Personal Info Form -->
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <!-- Order form  -->
          <b-col cols="12">
            <!-- <div class="d-flex border rounded"> -->
            <b-row class="flex-grow-1">
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :name="`User`"
                  rules="required"
                >
                  <b-form-group
                    label="User"
                    :label-for="`user`"
                    label-cols-md="3"
                    class="required"
                  >
                    <v-select
                      id="type"
                      v-model="orderData.user_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userOptions"
                      label="name"
                      :reduce="(option) => option.id"
                      :clearable="false"
                      placeholder="Select User"
                    />
                    <b-form-invalid-feedback
                    >
                    <!-- :state="getValidationState(validationContext)" -->
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Product field  -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :name="`Product`"
                  rules="required"
                >
                  <b-form-group
                    label="Product"
                    :label-for="`product`"
                    label-cols-md="3"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        id="product"
                        v-model="orderData.product_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        label="product_name"
                        :reduce="(option) => option.id"
                        :clearable="false"
                        placeholder="Select Product"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                    >
                    <!-- :state="getValidationState(validationContext)" -->
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Discount field  -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :name="`Discount`"
                  rules="required"
                >
                  <b-form-group
                    label="Discount"
                    :label-for="`discount`"
                    label-cols-md="3"
                    class="required"
                  >
                    <v-select
                      id="discount"
                      v-model="orderData.discount_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="discountOptions"
                      label="name"
                      :reduce="(option) => option.id"
                      :clearable="false"
                      placeholder="Select Discount"
                    />
                    <b-form-invalid-feedback
                    >
                    <!-- :state="getValidationState(validationContext)" -->
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Reserve Date field  -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :name="`Discount`"
                  rules="required"
                >
                  <b-form-group
                    label="Reserve Date"
                    :label-for="`discount`"
                    label-cols-md="3"
                    class="required"
                  >
                    <flat-pickr
                      v-model="orderData.reserve_date"
                      class="form-control"
                      :config="flatDateConfig"
                      placeholder="Reserve Date"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- order quantity field -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :name="`Order quantity`"
                  rules="required"
                >
                  <b-form-group
                    label="Order Quantity"
                    :label-for="`order_qty`"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      :id="`order_qty`"
                      v-model="orderData.order_qty"
                      @input="getProductTotal(orderData.order_qty)"
                      type="number"
                      placeholder="Order quantity"
                      />
                      <!-- :state="getValidationState(validationContext)" -->
                      <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- order amount field -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :name="`Order amount`"
                  rules="required"
                >
                  <b-form-group
                    label="Order Amount"
                    :label-for="`order_amt`"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      :id="`order_amt`"
                      v-model="totalBeforeDiscount"
                      type="number"
                      placeholder="Order amount"
                      disabled
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
              </b-col>

              <!-- order amount field -->
              <!-- <b-col cols="12" md="6" v-if="orderData.discount_id !== null">
                <validation-provider
                  #default="validationContext"
                  :name="`Order amount after discount`"
                  rules="required"
                >
                  <b-form-group
                    label="Order Amount After Discount"
                    :label-for="`order_amt`"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      :id="`order_amt`"
                      v-model="totalAfterDiscount"
                      type="number"
                      disabled
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
              </b-col> -->
            </b-row>
            <!-- </div> -->
          </b-col>

          <!-- Button: Submit & Reset Button.-->

          <hr />
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, alphaNum, email } from "@validations";
import { useToast } from "vue-toastification/composition";

export default {
  data() {
    console.log("order data ", this.orderData);
    return {
      flatDateConfig: {
        altInput: true,
        altFormat: "Y-m-d",
        allowInput: true,
        dateFormat: "Y-m-d",
      },
    };
  },
  mixins: [heightTransition],
  components: {
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  methods: {
    hide() {
      this.$router.replace("/orders-list");
    },
    onSubmit() {
      console.log("submit data", this.orderData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-order/updateOrder", this.orderData)
            .then((response) => {
              this.loading = false;

              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.replace("/orders-list");
            })
            .catch((error) => {
              this.loading = false;

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  initTrHeight() {
    this.trSetHeight(null);
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight);
    });
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const totalBeforeDiscount = ref(0);
    const totalAfterDiscount = ref(0);
    const userOptions = ref([]);
    const productOptions = ref([]);
    const discountOptions = ref([]);

    const resetData = () => {
      emit("refetch-data");
    };

    const getDiscountAmount = () => {
      let chosenDiscount = discountOptions.value.filter((x) => x.id === props.orderData.discount_id);
      totalAfterDiscount.value = totalBeforeDiscount.value - chosenDiscount[0].amount
    };

    const getProductTotal = (quantity) => {
      let chosenProduct = productOptions.value.filter((x) => x.id === props.orderData.product_id);
      totalBeforeDiscount.value = chosenProduct[0].product_price * quantity;
    };

    const resetorderData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetorderData);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", {
          user: true,
          product: true,
          discount: true,
        })
        .then((response) => {
          userOptions.value = response.data.users;
          productOptions.value = response.data.product;
          discountOptions.value = response.data.discount;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            userOptions.value = undefined;
            productOptions.value = undefined;
            discountOptions.value = undefined;
          }
        });
    };

    onMounted(() => {
      fetchOptions();
    });

    return {
      fetchOptions,
      loading,
      userOptions,
      productOptions,
      discountOptions,
      getProductTotal,
      getDiscountAmount,
      totalBeforeDiscount,
      totalAfterDiscount,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetorderData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
hr {
  width: calc(100% - 1.5rem);
  margin-top: 0px;
}

input::placeholder {
  color: lightgrey !important;
}

@media screen and (max-width: 767px) {
  .curr-btn {
    top: 51%;
  }
}
</style>
